import styled from "styled-components";

export const ContainerBody = styled.div`
  height: ${(props) => (props.modeWebchatFull ? "none" : `calc(465px - 42px)`)};
  padding: ${(props) => (props.modeWebchatFull ? "10px" : "none")};
`;

export const G4ContainerMessages = styled.div`
  position: relative;

  @media (min-width: 992px) and (max-width: 1439px) {
    height: ${(props) => (props.modeWebchatFull ? "40vh" : "none")};
  }

  height: ${(props) => (props.modeWebchatFull ? "50vh" : "368px")};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  behavior: smooth;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }
  ::-webkit-ms-scrollbar {
    width: 8px;
  }
  ::-webkit-ms-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }
  ::-webkit-o-scrollbar {
    width: 8px;
  }
  ::-webkit-o-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }

  .loaderContent {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 120px;
    align-items: center;
  }
`;

export const ProfileBallon = styled.img`
  width: 40px;
  height: 40px;
  background: #c0c0c0;
  border-radius: 50%;
  margin-left: 4px;
  margin-top: 12px;
  margin-bottom: auto;
`;

export const Arrow = styled.div`
  height: 30px;
  width: 30px;
  background: ${(props) => (props.isTransparent ? "transparent" : "#f9f9f9")};
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 15px -34px auto 11px;
`;

export const ContentMessages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentMessagesButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
`;
export const DropContainer = styled.div.attrs({
  className: "dropZone",
})`
  width: 30px;
  text-align: center;
  height: 20px;
  cursor: pointer;

  :focus {
    outline: 0;
  }
`;

export const Container = styled.div`
  height: 460px;
  width: 100%;

  .g4-content-upload {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PreviewUploadImage = styled.div`
  height: 130px;
  width: 130px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

export const PreviewUploadFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// export const HeaderUpload = styled.div`
//   background: #e84e0f;
//   color: #fff;
//   height: 40px;
//   display: flex;
//   align-items: center;

//   h4 {
//     margin-left: 20px;
//     font-family: Roboto, sans-serif;
//   }

//   svg {
//     cursor: pointer;
//     margin-left: auto;
//     margin-right: 20px;
//   }
// `;

export const G4TextRealTime = styled.div`
  min-height: 20px;
  font-style: italic;
  margin-left: 10px;
  margin-top: 10px;
`;

export const G4Typing = styled.span`
  animation: blinker 1s linear infinite;
`;

export const G4ContentInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 0 10px;
  padding: 3px 3px 3px 10px;

  #sendButtonConversationG4 > button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.8s;
    width: 30px;
    height: 30px;
    border: 0;
  }
`;

export const G4InputMessage = styled.input`
  height: 35px;
  flex: 1;
  margin: 0 0 0 10px;
  border: 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  resize: none;

  ${(props) => props.isRecordingAudio && `width: 90px;`}
`;

export const G4Button = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.8s;
  width: 30px;
  height: 30px;
  border: 0;

  ${(props) => props.color && `background: ${props.color};`}

  svg {
    transition: color 0.25s;
  }

  &:disabled {
    cursor: default;
    display: none;
  }

  &:focus {
    outline: 0;
  }
`;

export const G4Cliente = styled.div`
  min-width: ${(props) => (props.modeWebchatFull ? "200px" : "none")};
  max-width: ${(props) => (props.modeWebchatFull ? "500px" : "255px")};
  padding: 8px;
  margin: 5px;
  border-radius: 8px 0px 8px 8px;
  margin-left: auto;
  color: #000;
  box-shadow: 1px;
  background: #e7e7e7;
  //${(props) => props.color && `background: ${props.color};`}

  ${({ isVideo }) =>
    isVideo &&
    `
    max-width: 320px;
    padding: 4px;
  `}
`;

export const G4Atendente = styled.div`
  z-index: 3;
  background: #f9f9f9;
  min-width: ${(props) => (props.modeWebchatFull ? "200px" : "none")};
  max-width: ${(props) => (props.modeWebchatFull ? "500px" : "255px")};
  /* display: ${(props) => (props.displayType ? props.displayType : "")}; */
  border-radius: 9px;
  padding: 8px;
  margin: 5px;
  margin-right: auto;

  align-self: flex-start;
  color: #2f4f4f;
`;

export const G4AtendenteButton = styled.div`
  z-index: 3;
  min-width: ${(props) => (props.modeWebchatFull ? "200px" : "none")};
  max-width: ${(props) => (props.modeWebchatFull ? "500px" : "255px")};
  display: ${(props) => (props.displayType ? props.displayType : "")};
  border-radius: 20px;
  padding: 8px;
  margin: 5px;
  align-self: flex-start;
  color: #2f4f4f;
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  transition: 400ms;
  cursor: pointer;

  &&:hover {
    background: ${(props) => (props.borderColor ? props.borderColor : "#000")};
    color: #fff;
  }

  &&:hover .g4-message-option-link {
    color: #fff;
  }
`;

export const G4Wait = styled.div`
  //font-family: Raleway;
  margin: 10px 5px 0 5px;
  border-radius: 5px;
  padding: 5px;
  color: #fff;

  ${(props) => props.color && `background: ${props.color};`}
`;
