import styled from "styled-components";

export const G4FullWindow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${(props) => (props.color ? props.color : "#2196F3")};
  }

  input:focus + .slider {
    box-shadow: ${(props) => (props.color ? props.color : "#2196F3")};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const G4Container = styled.div`
  position: ${(props) => (props.modeWebchatFull ? "relative" : "fixed")};
  background-color: #fff;
  padding-top: ${(props) => (props.modeWebchatFull ? "10px" : "none")};
  bottom: ${(props) => (props.modeWebchatFull ? "1rem" : "3rem")};
  right: ${(props) => (props.modeWebchatFull ? "none" : "7rem")};
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.22);
  transition: height 0.5s 0.5s, opacity 600ms 0.4s;
  display: ${(props) => (props.openDisplay ? "block" : "none")};
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
  z-index: 20000;

  @media screen and (min-width: 1440px) {
    width: ${(props) =>
      props.modeWebchatFull && props.page !== "Conversation"
        ? "40rem"
        : props.modeWebchatFull && props.page === "Conversation"
        ? "87.5rem"
        : "325px"};

    height: ${(props) =>
      props.modeWebchatFull && props.page !== "Conversation"
        ? "30rem"
        : props.modeWebchatFull && props.page === "Conversation"
        ? "auto"
        : "465px"};
  }

  @media (min-width: 992px) and (max-width: 1439px) {
    width: ${(props) =>
      props.modeWebchatFull && props.page !== "Conversation"
        ? "25rem"
        : props.modeWebchatFull && props.page === "Conversation"
        ? "62.5rem"
        : "325px"};

    height: ${(props) =>
      props.modeWebchatFull && props.page !== "Conversation"
        ? "25rem"
        : props.modeWebchatFull && props.page === "Conversation"
        ? "none"
        : "465px"};

    bottom: ${(props) => (props.modeWebchatFull ? "1rem" : "3rem")};
    left: ${(props) => (props.modeWebchatFull ? "1rem" : "none")};
    right: ${(props) => (props.modeWebchatFull ? "1rem" : "1rem")};
  }

  @media screen and (max-width: 991px) {
    width: ${(props) => (props.modeWebchatFull ? "90%" : "325px")};
    height: ${(props) => (props.modeWebchatFull ? "auto" : "465px")};
    right: ${(props) => (props.modeWebchatFull ? "1rem" : "1rem")};
  }
`;

export const Hours = styled.div`
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  margin-left: 5px;
  // font-family: roboto;
`;

export const G4LogoOpen = styled.button`
  cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 7rem;
  border: #fff solid 5px;
  border-radius: 50%;
  padding: 5px;
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  ${(props) => props.color && `background: ${props.color};`}

  &&:hover {
    filter: brightness(0.9);
  }

  //Media para mobile
  @media screen and (max-width: 991px) {
    right: .5rem;
  }
`;
