import io from "socket.io-client";
import conversaAtendida from "./constraints/answeredConversation";
import atendenteEnviaMenssagem from "./constraints/attendantSendMessage";
import encerrarCoversaCliente from "./constraints/endCoversationClient";
import clienteTranferido from "./constraints/tranferredClient";
import entrouFilaCliente from "./http/queueClient";
import responderSurvey from "./constraints/answerSurvey";
import messageQueue from "./addMessageInQueue";
//import customParser from "socket.io-msgpack-parser";

const SOCKET_IO_URL = `${process.env.REACT_APP_PROXY_URL}public`;

const apiSocket = io.connect(SOCKET_IO_URL, {
  path: "/flex-chat-integration-socket",
  reconnectionAttempts: 3,
  transports: ["websocket"],
});

apiSocket.on("connect_error", (error) => {
  console.log(error?.message || "Socket Error!");
});

apiSocket.on("connect", (event) => {
  apiSocket.on("entrou_na_fila_cliente", (event) => {
    entrouFilaCliente(event);
  });

  apiSocket.on("cliente_transferido", (event) => {
    clienteTranferido(event);
  });

  apiSocket.on("conversa_transferida", (event) => {});

  apiSocket.on("conversa_atendida_cliente", (event) => {
    conversaAtendida(event);
  });

  apiSocket.on("atendente_enviou_mensagem", async (event) => {
    messageQueue.addMessageInQueue(event);
  });

  apiSocket.on("encerrar_conversa_cliente", (event) => {
    localStorage.removeItem("IdConversation");
    setTimeout(() => {
      encerrarCoversaCliente(event);
    }, 5000);
    localStorage.setItem("conversationAttendant", "false");
  });

  apiSocket.on("send_survey", (event) => {
    responderSurvey(event.conversa_id, event.survey, true);
  });
});

export default apiSocket;
