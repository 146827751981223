import styled from "styled-components";

export const G4Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: ${(props) => (props.modeWebchatFull ? "none%" : "423px")};
  background: #fff;
  border-radius: 0 0 13px 13px;

  .g4-inputWithIcon {
    height: 40px;
    width: 90%;
    background: #e7e7e7;
    display: flex;
    transition: ease 0.3s;
    border: 1px solid transparent;
    border-radius: 13px;
    justify-content: center;
    align-items: center;
  }

  .g4-inputWithIcon + .g4-inputWithIcon {
    margin-top: 7px;
  }
`;

export const G4LoginImgDiv = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  width: 100%;
  height: 172px;
  background-color: #fff;
`;

export const G4LoginImg = styled.div`
  width: 200px;
  height: 170px;
  background: url(${(props) => props.src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ContentInput = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #e7e7e7;
  width: ${(props) => (props.modeWebchatFull ? "80%" : "90%")};
  border: 1px solid ${(props) => props.border};
  border-radius: 7px;
  height: 43px;
  padding-left: 5px;
  margin-top: ${(props) => (props.modeWebchatFull ? "10px" : "5px")};

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const G4InputForm = styled.input`
  width: 80%;
  border-radius: 2px;
  padding: 5px;
  outline: none;
  border: 0;
  transition: 0.3s;
  background: #e7e7e7;
  font-size: 12px !important;
  transition: all 0.3s ease-out;
`;

export const MessageErro = styled.label`
  font-size: 13px !important;
  margin: 1px auto 3px 24px;
  //font-family: Roboto;
  font-weight: 600;
  color: red;
  height: ${(props) => props.size};
  transition: ease-in 0.3s;
`;

export const G4Button = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  //font-family: Roboto;
  margin-top: ${(props) => (props.modeWebchatFull ? "30px" : "auto")};
  margin-left: ${(props) => (props.modeWebchatFull ? 0 : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  overflow: hidden;
  transition: 0.8s;
  height: ${(props) => (props.modeWebchatFull ? "60px" : "42px")};
  width: ${(props) => (props.modeWebchatFull ? "40%" : "100%")};
  border-radius: ${(props) =>
    props.modeWebchatFull ? "6px" : "0px 0px 10px 10px"};

  ${(props) => props.color && `background: ${props.color};`}
  ${(props) => props.color && `border: 1px solid ${props.color};`}
  
  &:focus {
    outline: 0;
  }

  @media screen and (min-width: 1440px) {
    margin-top: ${(props) => (props.modeWebchatFull ? "60px" : "none")};
  }
`;

// &:hover {
//   ${(props) => props.hoverColor && `background: ${props.hoverColor};`}
//   ${(props) => props.hoverColor && `border: 1px solid ${props.hoverColor};`}
// }
