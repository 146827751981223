import React, { createContext, useState } from "react";

export const WebchatContext = createContext();

const isFullScreen = process.env.REACT_APP_FULLSCREEN_MODE === "true";

export function WebchatContextProvider({ children }) {
  const [modeWebchatFull, setModeWebchatFull] = useState(isFullScreen);
  const [inLoadingMessage, setInLoadingMessage] = useState(false);

  const changeToggle = (newValue) => {
    setModeWebchatFull(!newValue);
  };

  return (
    <WebchatContext.Provider
      value={{
        modeWebchatFull,
        changeToggle,
        inLoadingMessage,
        setInLoadingMessage,
      }}
    >
      {children}
    </WebchatContext.Provider>
  );
}
